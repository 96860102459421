import React from 'react'
import { Group } from '~/models'
import { TagsField } from '~/ui/app/tags'
import { memo } from '~/ui/component'
import { Center, LanguageField, Scroller, VBox } from '~/ui/components'
import { FormField } from '~/ui/form'
import { layout } from '~/ui/styling'
import PageField from '../../pages/PageField'

const MiscellaneousSettings = memo('MiscellaneousSettings', () => {

  return (
    <Scroller>
      <Center>
        <VBox width={480} gap={layout.padding.s}>
          <FormField name='identityGroupTags'>
            {bind => <TagsField Model={Group} {...bind}/>}
          </FormField>
          <FormField name='rootPage'>
            {bind => <PageField {...bind}/>}
          </FormField>
          <FormField name='languages'>
            {bind => <LanguageField {...bind} multi/>}
          </FormField>
        </VBox>
      </Center>
    </Scroller>
  )

})

export default MiscellaneousSettings