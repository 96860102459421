import React from 'react'
import { ScrollState } from 'react-scroll-manager'
import { escapeRegExp } from 'lodash'
import { LocalizedString } from '~/models'
import { RichTextField } from '~/ui/app/fields'
import { formatWidgetParams, WidgetsContext } from '~/ui/app/widgets'
import { memo } from '~/ui/component'
import {
  ClearButton,
  ClearButtonProps,
  Empty,
  HBox,
  Markdown,
  PushButton,
  Scroller,
  VBox,
  VBoxProps,
} from '~/ui/components'
import { useBoolean, useLocalizedString, usePrevious } from '~/ui/hooks'
import { useResourceTranslation } from '~/ui/resources'
import { animation, createUseStyles, layout, shadows } from '~/ui/styling'

export interface Props {
  value:    string | LocalizedString | null
  onCommit: (body: string | LocalizedString | null) => any
  empty?:   {title: string, message: string, children?: React.ReactNode}

  localized?: boolean

  editing?:          boolean
  requestStartEdit?: () => any
  requestStopEdit?:  () => any

  flex?:         VBoxProps['flex']
  scrollable?:   boolean

  editButton?:   Omit<ClearButtonProps, 'onTap'>
  extraButtons?: React.ReactNode
  widgets?:      boolean

  classNames?: React.ClassNamesProp
}

const RichTextBodyEditor = memo('RichTextBodyEditor', (props: Props) => {

  const {
    value,
    onCommit,
    empty,
    localized = false,
    editing = false,
    requestStartEdit,
    requestStopEdit,
    scrollable,
    flex = scrollable ? true : 'grow',
    editButton,
    extraButtons,
    widgets,
    classNames,
  } = props

  const {t} = useResourceTranslation('challenges')

  const [editingValue, setEditingValue] = React.useState<string | LocalizedString>(value ?? '')
  const prevValue = usePrevious(value)

  const translatedBody = useLocalizedString(editingValue ?? value)

  React.useEffect(() => {
    if (value !== prevValue && value !== editingValue) {
      setEditingValue(value ?? '')
    }
  }, [editingValue, editing, value, prevValue])

  const commit = React.useCallback(() => {
    const value = LocalizedString.clean(editingValue)
    onCommit?.(value)
    requestStopEdit?.()
  }, [editingValue, onCommit, requestStopEdit])

  const cancel = React.useCallback(() => {
    requestStopEdit?.()
  }, [requestStopEdit])

  //------
  // Scrolling

  const [scrollState, setScrollState] = React.useState<ScrollState>(ScrollState.default)
  const scrollbarWidth = scrollState.scrollbarWidth ?? 0

  //------
  // Widgets

  const [configuringWidget, startConfiguringWidget, endConfiguringWidget] = useBoolean()

  const setParams = React.useCallback((widget: string, params: Record<string, any>) => {
    // The widget UID is the character position where the widget snippet starts. Use a regex to update the widget.
    const regexp   = new RegExp(`\\$\\[${escapeRegExp(widget)}\\]\\(([\\w\\W]*?)\\)`)
    const nextBody = LocalizedString.map(value ?? '', body => body.replace(regexp, () => {
      return `$[${widget}](\n${formatWidgetParams(params, {multiline: true, indent: 2})}\n)`
    }))

    if (editing) {
      setEditingValue(nextBody)
    } else {
      onCommit(nextBody)
    }
  }, [value, editing, onCommit])

  const widgetsContext = React.useMemo((): WidgetsContext => ({
    setParams,
    startConfiguringWidget,
    endConfiguringWidget,
  }), [endConfiguringWidget, setParams, startConfiguringWidget])

  //------
  // Rendering

  const $ = useStyles()

  function render() {
    if (widgets) {
      return (
        <WidgetsContext.Provider value={widgetsContext}>
          {renderEditor()}
        </WidgetsContext.Provider>
      )
    } else {
      return renderEditor()
    }
  }

  function renderEditor() {
    return (
      <VBox classNames={$.richTextBodyEditor} flex={flex}>
        {editing ? (
          renderEditing()
        ) : (
          renderViewing()
        )}
      </VBox>
    )
  }

  function renderViewing() {
    if (value == null) {
      return renderEmpty()
    }

    const classModifiers = {
      atStart:   scrollState.atStart,
      atEnd:     scrollState.atEnd,
      scrollbar: scrollbarWidth > 0,
    }

    return (
      <VBox flex={flex} classNames={[$.viewing, classNames]} onDoubleClick={requestStartEdit}>
        {scrollable ? (
          <VBox flex={flex} classNames={[$.viewScroller, classModifiers]}>
            <Scroller
              onScrollStateChange={setScrollState}
              scrollStateKey={value}
              scrollThrottle={200}
              flex={flex}
              children={renderViewingBody()}
            />
            <div classNames={[$.shadow, 'top']}/>
            <div classNames={[$.shadow, 'bottom']}/>
          </VBox>
        ) : (
          renderViewingBody()
        )}

        {renderButtons()}
      </VBox>
    )
  }

  function renderViewingBody() {
    return (
      <Markdown classNames={$.viewingBody}>
        {translatedBody}
      </Markdown>
    )
  }

  function renderButtons() {
    return (
      <HBox classNames={$.buttons}>
        {renderEditButton()}
        {extraButtons}
      </HBox>
    )
  }

  function renderEditButton() {
    if (requestStartEdit == null) { return null }

    return (
      <PushButton
        icon='pencil'
        caption={t('buttons:edit')}
        hanging
        small

        {...editButton}

        onTap={requestStartEdit}
      />
    )
  }

  function renderEditing() {
    return (
      <RichTextField
        classNames={[$.bodyField, classNames]}
        value={editingValue}
        onChange={setEditingValue}
        borderTopRadius={0}
        autoFocus={true}
        localized={localized}
        onCommit={commit}
        commitOnBlur={false}
        renderHeader={renderEditingButtons}
        flex={flex}
        scrollable={scrollable}
        acceptWidgets={widgets}
        enabled={!configuringWidget}
        showFocus={false}
      />
    )
  }

  function renderEmpty() {
    if (empty == null) { return null }

    return (
      <Empty
        flex
        {...empty}
      />
    )
  }

  function renderEditingButtons() {
    return (
      <VBox classNames={$.editingButtons}>
        <HBox justify='right'>
          <ClearButton
            icon='check'
            caption={t('buttons:done')}
            onTap={commit}
            padding='both'
          />
          <ClearButton
            icon='cross'
            onTap={cancel}
            dim
            padding='both'
          />
        </HBox>
      </VBox>
    )
  }

  return render()

})

export default RichTextBodyEditor

export const hangingButtonHeight = 28

const useStyles = createUseStyles(theme => ({
  richTextBodyEditor: {
    position: 'relative',
    overflow: 'hidden',
  },

  viewing: {
    position: 'relative',
  },

  buttonRow: {
    position: 'absolute',
    ...layout.responsiveProp({right: layout.padding.s}),

    '& > :not(:first-child)': {
      '&, &::after': {
        borderBottomLeftRadius: '0 !important',
      },
    },
    '& > :not(:last-child)': {
      '&, &::after': {
        borderBottomRightRadius: '0 !important',
      },
    },
  },

  buttons: {
    extend: 'buttonRow',
    top:    0,

    willChange: 'transform',
    transform:  `translateY(${-hangingButtonHeight - 4}px)`,
    transition: animation.transitions.short('transform'),

    '$richTextBodyEditor:hover &': {
      transform:  'translateY(0)',
    },
  },

  editingButtons: {
    background: theme.bg.subtle,
    boxShadow:  shadows.depth(1),
  },

  viewingToolbar: {
    padding: [0, layout.padding.inline.l],
  },

  viewScroller: {
    position: 'relative',

    '&.scrollbar $shadow': {
      right: 15 + layout.padding.inline.m,
    },

    '&:not(.atStart) $shadow.top': {
      opacity: 1,
    },
    '&:not(.atEnd) $shadow.bottom': {
      opacity: 1,
    },
  },

  shadow: {
    position: 'absolute',
    left:     layout.padding.inline.l,
    right:    layout.padding.inline.l,
    height:   6,

    willChange: 'opacity',
    transition: animation.transitions.short('opacity'),

    '&.top':    {
      top:       0,
      boxShadow: ['inset', 0, 2, 6, -4, shadows.shadowColor],
    },
    '&.bottom': {
      bottom:    0,
      boxShadow: ['inset', 0, -2, 6, -4, shadows.shadowColor],
    },

    opacity: 0,

    pointerEvents: 'none',
    overflow:      'hidden',
  },

  viewingBody: {
    padding: layout.padding.inline.l,
  },

  bodyField: {
    flex:         [1, 0, 'auto'],
    borderRadius: '0 !important',

    '&::after': {
      boxShadow: 'none !important',
    },
  },
}))